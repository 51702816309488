import { Address } from '@/types/supabase.types'
import { OrderInfoType } from 'pages/shipping_details/[marketplaceId]'

export const validateOrderInfo = (i: OrderInfoType, cpPrefixes: string[]) =>
{
  let validateShippingAddress = false
  let validateBillingAddress = false

  if (i.shipping_address.passport_country_code) {
    validateShippingAddress = validateAddressPassport(i.shipping_address, cpPrefixes, i.shipping_address.passport_country_code)
  } else {
    validateShippingAddress = validateAddress(i.shipping_address, cpPrefixes) || false
  }

  if (i.billing_address.passport_country_code) {
    validateBillingAddress = validateAddressPassport(i.billing_address, cpPrefixes, i.billing_address.passport_country_code)
  } else {
    validateBillingAddress = validateAddress(i.billing_address, cpPrefixes) || false
  }

  return validateBillingAddress && validateShippingAddress
}

export const validateAddressPassport = (a: Address, cpPrefixes: string[], passportCountryCode: string) =>
  a.email &&
  validateEmail(a.email) &&
  a.postal_code &&
  validateCP(a.postal_code, cpPrefixes) &&
  a.name &&
  a.city &&
  // This might cause problems for companies' payments
  (!a.is_company ? a.surnames : true) &&
  a.street &&
  a.phone_number &&
  a.number &&
  a.nif &&
  validatePassport(a.nif, passportCountryCode)

export const validateAddress = (a: Address, cpPrefixes: string[]) =>
  a.email &&
  validateEmail(a.email) &&
  a.postal_code &&
  validateCP(a.postal_code, cpPrefixes) &&
  a.name &&
  a.city &&
  // This might cause problems for companies' payments
  (!a.is_company ? a.surnames : true) &&
  a.street &&
  a.phone_number &&
  a.number &&
  a.nif &&
  validateNif(a.nif,a.is_company)

export const validateEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const validateCP = (postalCode: string, cpPrefixes: string[]) => {
  let valid = false
  cpPrefixes.forEach((cp: string) => {
    if (postalCode.startsWith(cp)) valid = true
  })
  return valid
}

export const validateNif = (nif: string, isCompany: boolean = false) => {
  if (!nif) return false
  if(isCompany) return true // do not validate company CIFs for now
  let number: string, letter: string, letter2: string
  var regex = /^[XYZ]?\d{5,8}[A-Z]$/

  nif = nif.toUpperCase()

  if (regex.test(nif) === true) {
    number = nif.substr(0, nif.length - 1)
    number = number.replace('X', '0')
    number = number.replace('Y', '1')
    number = number.replace('Z', '2')
    letter2 = nif.substr(nif.length - 1, 1)
    const n = Number.parseInt(number) % 23
    letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letter = letter.substring(n, n + 1)
    if (letter != letter2) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export const validatePassport = (passportNumber: string, countryCode: string) => {
  const regexPatterns: any = {
    'United States': /^\d{8,9}$/,
    'Austria': /^[A-z] ?[0-9]{7}$/,
    'Belgium': /^[A-Z]{2}\d{6}$/,
    'Bulgaria': /^[0-9]{9}$/,
    'Croatia': /^[0-9]{9}$/,
    'Cyprus': /^[A-z][0-9]{6,8}$/,
    'Czech Republic': /^[0-9]{8}$/,
    'Denmark': /^[0-9]{9}$/,
    'Estonia': /^[A-z][0-9]{7}$/,
    'Finland': /^[0-9]{12}$/,
    'France': /^[0-9]{2}[A-z]{2}[0-9]{5}$/,
    'Germany': /^[CFGHJK0-9][0-9]{3}[CHJKLMNPRTVWXYZ0-9]{5}[0-9]$/,
    'Greece': /^[A-z]{2}\d{7}$/,
    'Hungary': /^[A-z][0-9]{6,7}$/,
    'Ireland': /^[A-z][0-9]{7}$/,
    'Italy': /^[A-z0-9][0-9]{7}$/,
    'Latvia': /^[A-z0-9]{2}\d{7}$/,
    'Lithuania': /^[A-z0-9]{8}$/,
    'Luxembourg': /^[A-z0-9]{7,8}$/,
    'Malta': /^\d{7}$/,
    'Netherlands': /^[A-z0-9]{9}$/,
    'Poland': /^[A-z]{2}[0-9]{7}$/,
    'Portugal': /^[A-z]{1}\d{6}$/,
    'Romania': /^\d{8,9}$/,
    'Slovakia': /^[A-z][0-9]{7}$/,
    'Slovenia': /^[P][A-Z][0-9]{7}$/,
    'Spain': /^[A-Z]{2,3}\d{6,7}$/,
    'Sweden': /^\d{8}$/,
    'United Kingdom': /^([A-Z]{2}\d{6}[A-Z]|\d{9})$/,
    'Canada': /^[A-Z]{2}[0-9]{6}$/,
    'Australia': /^[A-Z]{1,2}\d{7}$/,
    'India': /^[A-Z]\d{7}$/,
    'Brazil': /^\d{8}$/,
    'Japan': /^[A-z]{2}\d{7}$/,
    'Mexico': /^[A-Z]{3}\d{6}$/,
    'Russia': /^[A-z0-9]{9}$/,
    'Argentina': /^\d{9}$/,
    'Turkey': /^[A-Z]\d{8}$/
    // Add more countries as needed
  };

  const regex = regexPatterns[countryCode]
  if (!regex) {
    return false
  }

  return regex.test(passportNumber)
}

